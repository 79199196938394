import React from 'react';
import i18next from 'i18next';
import * as ReactI18next from 'react-i18next';
import { Helmet } from 'react-helmet';
import { defaultLanguage } from '../config/languages';
import redirectToLocalizePage from '../utils/redirect-to-localized-page';

export const AlternateLinksContext = React.createContext([]);

export function wrapWithI18nProvider({ element, props }) {
  const alternateLinks = props.pageContext && props.pageContext.alternateLinks;
  const i18n = i18next
    .createInstance({
      lng: props.pageContext.language,
      fallbackLng: defaultLanguage.locale,
      interpolation: {
        escapeValue: false,
      },
      initImmediate: false,
      keySeparator: false,
      resources: props.pageContext.translations,
      // debug: true,

      react: {
        transSupportBasicHtmlNodes: true,
        useSuspense: false,
        wait: true,
      },
    })
    .use(ReactI18next.initReactI18next);

  i18n.init();

  // Redirect to correct language
  // redirectToLocalizePage({
  //   alternateLinks,
  //   location: props.location,
  //   currentLanguage: props.pageContext.language,
  // });

  return (
    <ReactI18next.I18nextProvider i18n={i18n}>
      <AlternateLinksContext.Provider value={alternateLinks}>
        {
          <Helmet
            htmlAttributes={{
              lang: props.pageContext.language,
            }}
          >
            {props.pageContext &&
              props.pageContext.alternateLinks &&
              props.pageContext.alternateLinks.map(link => (
                <link
                  rel="alternate"
                  hrefLang={link.shortLangCode}
                  href={link.url}
                  key={link.path}
                />
              ))}
          </Helmet>
        }
        {element}
      </AlternateLinksContext.Provider>
    </ReactI18next.I18nextProvider>
  );
}
