exports.languages = {
  en: {
    isDefault: true,
    path: `en`,
    locale: `en`,
    label: 'English',
    labelShort: 'EN',
    siteLanguage: `en`,
    ogLanguage: `en_US`,
  },
};

exports.languagesCodes = Object.keys(exports.languages);
exports.defaultLanguage = Object.keys(exports.languages).find(
  langCode => exports.languages[langCode].isDefault
);
exports.namespaces = [
  'Global',
  'Header',
  'TravelersPage',
  'TravelersPricing',
  'BusinessPage',
  'PressQuotes',
  'SavingsCalculator',
  'Footer',
  'About',
  'DutyFreeDistrictPage',
];
